/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../../generated/graphql-types";
import {
  Article,
  Hero,
  Statement,
  ValuesCollection,
} from "../../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../../layouts";
import socialHead2 from "../../../images/Banner-RSPCA.jpg";
import leftIcon from "../../../images/Left Button.svg";
import inline from "../../../images/Inline-RSPCA.jpg";
const inlineImage = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { inline },
      srcSet: inline,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  nodes.map((n) => console.log(n.id));
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="RSCPA | Partners in Performance | Global Management Consultancy"
      description={sectorPage?.description}
    >
      <Hero
        title="RSPCA"
        video=""
        image={Image}
        short={true}
        headings=""
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title=""
        headline=""
        // cleanedMessage={rtfFromText(
        //   "We need to feel safe and supported to speak up, share our ideas and spark courageous conversations that help bring out the best in all of us. We know that our diverse backgrounds, experiences and perspectives help us to see things differently to the person next to us. As an organisation, we need that diversity of viewpoints and innovative thinking from our people to achieve the game-changing results we aim for when partnering with clients. Our simple belief is that we achieve better outcomes when people feel safe to be themselves. That is why we work hard to create a working environment in which all our people can belong, contribute and thrive. As a firm, we are committed to building an inclusive, diverse, flexible, and accessible culture in which all our people can unleash their full potential. As part of International Women's Day, we sat down with a few of our female leaders across the globe to bring these important conversations to the forefront. "
        // )}
        cleanedMessage={[
          {
            type: "paragraph",
            children: [
              {
                text: "The RSPCA in Australia is a federation, with an RSPCA branch in each state and territory and RSPCA Australia as the national body. The state and territory member Societies provide services to animals in need through their shelters and inspectorates. ",
              },
            ],
          },
          {
            type: "paragraph",
            children: [
              {
                text: "RSCPA Approved Farming Scheme (AFS) works with producers, retailers and brands to improve the welfare of farm animals by reviewing farming standards and providing certification to brands that pass the requirements. ",
              },
            ],
          },
        ]}
        id={""}
        className=""
        Frompage="caseStudy"
      />
      <ValuesCollection
        key=""
        title="Organisational purpose and mission statement"
        heading={[
          {
            children: [
              {
                text: "From the national office, RSPCA Australia works to influence animal welfare policy, practice and legislation across the country. They do this through various avenues, including working with government and industries, communicating, and contributing to animal welfare science, running the RSPCA Approved Farming Scheme (AFS), and working with supporters through targeted advocacy and education campaigns. ",
              },
            ],
            type: "paragraph",
          },
        ]}
        statements={[
          {
            id: "",
            title:
              "1. What perceptions donors have of the organisation, especially in terms of trust, credibility and delivery of meaningful student outcomes",
            heading: [],
          },
          {
            id: "",
            title: "2. How to best engage donors and make them feel valued ",
            heading: [],
          },
          {
            id: "",
            title:
              "To date, minimal studies and surveys have been conducted to have a comprehensive understanding of what donors think despite their high importance for supporting our client’s vision.  ",
            heading: [],
          },
        ]}
      />
      <Article
        side="right"
        id={""}
        image={inlineImage}
        alt={""}
        detailsRTF={[
          {
            children: [
              {
                text: "An open‑pit gold miner had just completed a transition to owner‑mining, and faced many operational challenges including old mining equipment and a high‑maintenance processing plant.",
              },
            ],
            type: "heading-three",
          },
          {
            children: [
              {
                text: "Unit costs were under severe pressure: fixed costs increased by 100% following the transition; rising fuel and power costs; main pit getting deeper and haul distances longer.",
              },
            ],
            type: "paragraph",
          },
        ]}
        articlewithContent="YES"
        Articletitle="Challenge faced by organisation "
        heading={[
          {
            children: [
              {
                text: "The RSPCA AFS is not-for-profit organisation and generates revenues to cover costs through licensing agreements with brands and retailers. Since FY19, RSPCA AFS has been operating at a loss and saw a $117k loss in FY21. ",
              },
            ],
            type: "paragraph",
          },
        ]}
        statements={[
          {
            id: "",
            title:
              "RSPCA AFS was using multiple pricing models which created a significant administrative burden for both licensees and the scheme. ",
            heading: [],
          },
        ]}
      />
      {/* <ValuesCollection
        key=""
        title="Challenge faced  
        by organisation "
        changeDesign="YES"
        styleclass="pt-5px"
        heading={[
          {
            children: [
              {
                text: "The RSPCA AFS is not-for-profit organisation and generates revenues to cover costs through licensing agreements with brands and retailers. Since FY19, RSPCA AFS has been operating at a loss and saw a $117k loss in FY21. ",
              },
            ],
            type: "paragraph",
          },
        ]}
        statements={[
          {
            id: "",
            title:
              "RSPCA AFS was using multiple pricing models which created a significant administrative burden for both licensees and the scheme. ",
            heading: [],
          },
        ]}
      /> */}
      <ValuesCollection
        key=""
        title="How we helped "
        changeDesign="YES"
        butlletdesign="YES"
        styleclass="pt-8px"
        className="bg-light-blue"
        statements={[
          {
            id: "",
            title:
              "Conducted interviews with key stakeholders to determine the guiding principles and framework for assessing potential pricing models.",
            heading: [],
          },
          {
            id: "",
            title:
              "Identified gaps to be addressed in the current pricing scheme and segmentation; and developed recommendations to address them.   ",
            heading: [],
          },
          {
            id: "",
            title:
              "Assessed potential pricing models based on support of objectives and mitigation of challenges (financial and non-financial). ",
            heading: [],
          },
        ]}
      />
      <ValuesCollection
        key=""
        title="Impact achieved "
        changeDesign="YES"
        styleclass="pt-7px"
        heading={[
          {
            children: [
              {
                text: "Designed a new pricing model which would generate revenue for RSPCA AFS to expand the standards into new areas and reduce admin burden for licensees. ",
              },
            ],
            type: "paragraph",
          },
        ]}
        statements={[
          {
            id: "",
            title:
              "Developed an implementation plan with detailed actions for RSPCA AFS to transition to the new pricing model.",
            heading: [],
          },
          {
            id: "",
            title:
              "Prepared a communication strategy for RSPCA to communicate new pricing model to stakeholders.  ",
            heading: [],
          },
        ]}
      />
      <p className="col-span-12 col-start-2 text-center p-20px">
        To learn more or to contribute to this great organisation, please visit
        &nbsp;
        <b>
          <a href="https://www.rspca.org.au/" className="text-dark-blue">
            https://www.rspca.org.au/
          </a>
        </b>
      </p>
      <>
        <section className="py-section page-grid bg-white-1">
          <a
            href="/en/our-social-values"
            className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6 bg"
          >
            <img
              src={leftIcon}
              alt="Left"
              className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
            />
            Our social values
          </a>
        </section>
      </>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
